/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react';

export function getSearch() {
  const search = typeof window !== `undefined` ? window.location.search : '';
  return search;
}

export function useSearch() {
  const [search, setSearch] = useState('');
  useEffect(() => {
    const q = typeof window !== 'undefined' ? window.location.search : '';
    setSearch(q);
  }, []);
  return search;
}

export function loadScript(src, callback) {
  const script = document.createElement('script');
  const head = document.getElementsByTagName('head')[0];
  script.type = 'text/javascript';
  script.charset = 'UTF-8';
  script.src = src;
  if (script.addEventListener) {
    script.addEventListener(
      'load',
      function () {
        callback();
      },
      false
    );
  } else if (script.attachEvent) {
    script.attachEvent('onreadystatechange', function () {
      const target = window.event.srcElement;
      if (target.readyState === 'loaded') {
        callback();
      }
    });
  }
  head.appendChild(script);
}

export function loadQrcode(type = 'footer') {
  const data = {
    article: {
      uuid: '7624c1ec489e43e59454e444321d82a2',
      element: '._cl_qrcode_article',
      qrcode:
        'https://host.huiju.cool/qrimg/7624c1ec489e43e59454e444321d82a2?num=0.7867684371875958',
    },
    float: {
      uuid: '21c91945b01f4a2fa8ce6aef01ea0508',
      element: '._cl_qrcode_float',
      qrcode:
        'https://host.huiju.cool/qrimg/21c91945b01f4a2fa8ce6aef01ea0508?num=0.4615796408087802',
    },
    footer: {
      uuid: '5116c1b96f7e4cafb570ab4da51b13d0',
      element: '._cl_qrcode_footer',
      qrcode:
        'https://host.huiju.cool/qrimg/5116c1b96f7e4cafb570ab4da51b13d0?num=0.8952822343799103',
    },
  };

  setTimeout(function () {
    (function (c, e, f, b) {
      function a(j) {
        const h = `${j}=`;
        const g = document.cookie.split(';');
        // eslint-disable-next-line no-plusplus
        for (let k = 0; k < g.length; k++) {
          let l = g[k];
          // eslint-disable-next-line eqeqeq
          while (l.charAt(0) == ' ') {
            l = l.substring(1);
          }
          // eslint-disable-next-line eqeqeq
          if (l.indexOf(h) == 0) {
            return l.substring(h.length, l.length);
          }
        }
        return '';
      }
      (function () {
        const h = a('c__utma');
        // const cl_cookie_enabled = a('cl_cookie_enabled');
        document.querySelectorAll(b).forEach(function (node) {
          var imgs = node.getElementsByTagName('img');
          var g = imgs.length > 0 ? imgs[0] : document.createElement('img');
          g.setAttribute('style', 'width: 100%');
          // if (h && cl_cookie_enabled === '7') {
          if (h) {
            g.setAttribute('src', c + '?uuid=' + e + '&tid=' + f + '&utma=' + h);
          } else {
            g.setAttribute('src', data[type].qrcode);
          }
          if (imgs.length === 0) {
            node.appendChild(g);
          }
        });
      })();
    })('//host.huiju.cool/qrcode/binding', data[type].uuid, '1238467299', data[type].element);
  }, 1500);
}

// 加载动态企微二维码
export function loadQrcodeQw(typeId) {
  const dataSource = {
    type1: {
      bindingId: '4ca7',
      path: '//wework.qpic.cn/wwpic/800140_x5QgxphoRY-vTLP_1629183595/0',
      qrId: '_cl_wework_qrcode_1',
    },
    type2: {
      bindingId: '4ca6',
      path: '//wework.qpic.cn/wwpic/531798_noRhrwBfQECv8cG_1629183583/0',
      qrId: '_cl_wework_qrcode_2',
    },
    type3: {
      bindingId: '4ca2',
      path: '//wework.qpic.cn/wwpic/204983_qKgRlUXWTc-TFoU_1628841509/0',
      qrId: '_cl_wework_qrcode_footer2_pc',
    },
    type4: {
      bindingId: '4ca2',
      path: '//wework.qpic.cn/wwpic/204983_qKgRlUXWTc-TFoU_1628841509/0',
      qrId: '_cl_wework_qrcode_footer2_mobile',
    },
    type5: {
      bindingId: '4cae',
      path: '//wework.qpic.cn/wwpic/372644_qtaP0GcTSZqHp09_1629861532/0',
      qrId: '_cl_wework_qrcode_rightSide',
    },
  };
  const data = dataSource[typeId];
  (function (c, e, o, b) {
    var imgC = document.getElementById(b);
    if (!imgC) {
      return false;
    }
    var imgs = imgC.getElementsByTagName('img');
    var g = imgs.length > 0 ? imgs[0] : document.createElement('img');
    g.setAttribute('style', 'width: 100%');
    g.setAttribute('src', o);
    if (imgs.length === 0) {
      imgC.appendChild(g);
    }

    function d() {
      var ru = (function (j) {
          for (var h = j + '=', g = document.cookie.split(';'), k = 0; k < g.length; k++) {
            for (var l = g[k]; ' ' == l.charAt(0); ) l = l.substring(1);
            if (0 == l.indexOf(h)) return l.substring(h.length, l.length);
          }
          return '';
        })('c__utma'),
        oReq = (function () {
          let cl = [];
          return (
            location.search
              .slice(1)
              .split('&')
              .forEach(function (a) {
                a = a.split('=');
                0 === a[0].indexOf('cl_') && a[1] && cl.push(a[0] + '=' + a[1]);
              }),
            0 < cl.length ? '&' + cl.join('&') : ''
          );
        })(),
        ru = c + '?uuid=' + e + '&utma=' + ru + oReq;
      oReq = new XMLHttpRequest();
      (oReq.onload = function () {
        try {
          var r = JSON.parse(this.responseText);
          r && r.qrCode && g.setAttribute('src', r.qrCode);
        } catch (e) {}
      }),
        oReq.open('GET', ru),
        oReq.send();
    }

    'undefined' != typeof _cl_tracker && _cl_tracker.ready
      ? _cl_tracker.ready(d)
      : setTimeout(d, 3e3);
  })('//a.huiju.cool/e/extwechatcorp/pgw/livecode/binding', data.bindingId, data.path, data.qrId);
}

// 加载百度热力图埋点脚本
export function loadBadiuhmScript() {
  (function () {
    function docReady(fn) {
      // see if DOM is already available
      if (document.readyState === 'complete' || document.readyState === 'interactive') {
        // call on next available tick
        setTimeout(fn, 1);
      } else {
        document.addEventListener('DOMContentLoaded', fn);
      }
    }
    var _hmt = _hmt || [];
    docReady(function () {
      var hm = document.createElement('script');
      hm.src = 'https://hm.baidu.com/hm.js?0159b734d486d192423cb3d73b018d04';
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(hm, s);
    });
  })();
}

// 加载ptengine热力图
export function loadPtengineScript() {
  (function () {
    function docReady(fn) {
      // see if DOM is already available
      if (document.readyState === 'complete' || document.readyState === 'interactive') {
        // call on next available tick
        setTimeout(fn, 1);
      } else {
        document.addEventListener('DOMContentLoaded', fn);
      }
    }
    docReady(function () {
      if (!window._pt_sp_2) {
        window._pt_lt = new Date().getTime();
        window._pt_sp_2 = [];
        _pt_sp_2.push('setAccount,756c7115');
        var _protocol = 'https:' == document.location.protocol ? ' https://' : ' http://';
        var atag = document.createElement('script');
        atag.type = 'text/javascript';
        atag.async = true;
        atag.src = _protocol + 'js.ptengine.cn/756c7115.js';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(atag, s);
      }
    });
  })();
}
